import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import useConfig from 'hooks/useConfig';

const DirectLoginCallback = () => {
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    const RefID = searchParams.get('RefID') ?? searchParams.get('RefId');
    const error_code = searchParams.get('error_code');
    const lang = searchParams.get('lang');
    const { onChangeLocale } = useConfig();

    useEffect(() => {
        if (lang) {
            switch (lang) {
                case 'zh_HK':
                    onChangeLocale('zh-HK');
                    break;
                case 'zh_CN':
                    onChangeLocale('zh-CN');
                    break;
                case 'en':
                    onChangeLocale('en');
            }
        }
        let url = '';
        if (state) {
            url = `${window.config.AUTH_SERVICE_DOMAIN}/iamsmart/directLoginCallback?state=${state}`;
        } else {
            url = `${window.config.AUTH_SERVICE_DOMAIN}/iamsmart/api/v2/directLoginCallback?RefID=${RefID}`;
        }
        if (code) {
            url = `${url}&code=${code}`;
        }
        if (error_code) {
            url = `${url}&error_code=${error_code}`;
        }
        window.location.href = url;
    }, []);
    return <></>;
};

export default DirectLoginCallback;
