import useApiClient from 'hooks/useApiClient';
import useAuth from 'hooks/useAuth';
import useConfig from 'hooks/useConfig';
import { useConfirmDialog } from 'hooks/useConfirmDialog';
import { useLoading } from 'hooks/useLoading';
import { createContext, useCallback, useEffect, useState } from 'react';
import LinkupPopup from 'ui-component/popup/LinkupPopup';
import { browserType } from 'utils/checkBrowser';

interface IamsmartContextProps {
    login: () => void;
    linkup: () => void;
    unlink: () => void;
    openLinkupPopup: (step?: number) => void;
    moreInfoURL: string;
    linkupStep: number;
    source: string;
}

const iamSmartWebsiteURLtc = 'https://www.iamsmart.gov.hk/tc/';
const iamSmartWebsiteURLsc = 'https://www.iamsmart.gov.hk/sc/';
const iamSmartWebsiteURLen = 'https://www.iamsmart.gov.hk/en/';

const initState: IamsmartContextProps = {
    login(): {} {
        throw new Error('Function not implemented.');
    },
    linkup(): void {
        throw new Error('Function not implemented.');
    },
    unlink(): void {
        throw new Error('Function not implemented.');
    },
    moreInfoURL: '',
    openLinkupPopup(): void {
        throw new Error('Function not implemented.');
    },
    linkupStep: 0,
    source: ''
};

export const IamsmartContext = createContext<IamsmartContextProps>(initState);

const convertLocale = (lang: string) => {
    switch (lang) {
        case 'zh-HK':
            return 'zh-HK';
        case 'zh-CN':
            return 'zh-CN';
        case 'en':
        default:
            return 'en-US';
    }
};

export const IamsmartContextProvider = ({ children }: any) => {
    const { locale } = useConfig();
    const { user, setIamsmart } = useAuth();
    const loading = useLoading();
    const loginURL = `${window.config.AUTH_SERVICE_DOMAIN}/iamsmart/auth`;
    const linkupURL = `${window.config.AUTH_SERVICE_DOMAIN}/iamsmart/linkup`;
    const [tab, setTab] = useState<Window | null>(null);
    const [linkupPopupOpen, setLinkupPopupOpen] = useState(false);
    const source = browserType();
    const lang = convertLocale(locale);
    const dialog = useConfirmDialog();
    const { userService } = useApiClient();
    const [linkupStep, setLinkupStep] = useState<number>(0);
    const login = () => {
        window.location.href = `${loginURL}?source=${source}&lang=${lang}`;
    };

    const getMoreInfoURL = () => {
        switch (lang) {
            case 'zh-HK':
                return iamSmartWebsiteURLtc;
            case 'zh-CN':
                return iamSmartWebsiteURLsc;
            case 'en-US':
            default:
                return iamSmartWebsiteURLen;
        }
    };

    const moreInfoURL = getMoreInfoURL();

    const onLinkupSuccess = useCallback(() => {
        setLinkupPopupOpen(false);
        setIamsmart(true);
        dialog.setDialog({
            title: 'iamsmart.linkup.success',
            content: 'iamsmart.linkup.success.content',
            actionBtn: 'ok'
        });
    }, [dialog, setIamsmart]);

    const tabMessageListener = useCallback(
        (event: MessageEvent) => {
            console.log(event);
            if (event.data === 'D00000') {
                onLinkupSuccess();
            } else {
                dialog.setDialog({
                    title: 'iamsmart.linkup.fail',
                    content: event.data,
                    cancelBtn: 'ok'
                });
            }
            tab?.close();
            setTab(null);
        },
        [dialog, onLinkupSuccess, tab]
    );
    useEffect(() => {
        if (tab) {
            window.addEventListener('message', tabMessageListener);
        } else {
            window.removeEventListener('message', tabMessageListener);
        }
    }, [tab, tabMessageListener]);

    const linkup = () => {
        if (!tab || tab.closed) {
            fetch(linkupURL, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    Accept: 'application/json',
                    Authorization: `Bearer ${user?.token}`
                },
                body: `source=${source}&lang=${lang}`
            })
                .then(async (response) => {
                    if (response.status === 200) {
                        const body = await response.json();
                        if (body && body.redirectUrl) {
                            const newTab = window.open(body.redirectUrl);
                            setTab(newTab);
                        }
                    } else {
                        const body = await response.json();
                        console.log(body);
                    }
                })
                .catch(async (err) => {
                    console.error(`login fetch error:${JSON.stringify(err)}`);
                });
        }
    };

    const openLinkupPopup = (step: number = 0) => {
        setLinkupStep(step);
        setLinkupPopupOpen(true);
    };

    const unlink = () => {
        const doUnlink = async () => {
            try {
                const resp = await userService.unlinkIamsmart();
                if (resp) {
                    setIamsmart(false);
                    dialog.setDialog({
                        title: 'iamsmart.unlink',
                        content: 'iamsmart.unlink.success',
                        actionBtn: 'ok'
                    });
                }
            } catch (err) {
                console.error(err);
            }
        };
        doUnlink();
    };
    return (
        <IamsmartContext.Provider value={{ login, openLinkupPopup, linkup, unlink, moreInfoURL, linkupStep, source }}>
            {children}
            <LinkupPopup
                open={linkupPopupOpen}
                onClose={() => {
                    tab?.close();
                    setLinkupPopupOpen(false);
                }}
            />
        </IamsmartContext.Provider>
    );
};
