import { createContext, FC } from 'react';
import useAuth from '../hooks/useAuth';
import util from 'util';
import { CustomRequestConfig, ICustomRequestConfig } from '../types/request/custom-request-config';
import axios from 'axios';
import { ISelectionResponse } from '../types/response/selection-response';
import { APPLICATION, UNSTRUCTURED_APPLICATION, PUPLIC, SIGNER, SYSTEM, USER } from '../constants/API';
import { IUserRoleResponse } from '../types/response/user-role-response';
import { IGetApplicationsRequest } from '../types/request/get-applications-request';
import { IGetApplicationsResponse } from '../types/response/get-applications-response';
import { useConfirmDialog } from '../hooks/useConfirmDialog';
import { IApplicationTypeResponse } from '../types/response/application-type-response';
import { CreateUnstructuredApplication, SaveUnstructuredApplicationData } from 'types/request/api.type';
import { IGetNoticesRequest } from 'types/request/get-notices-request';
import { IGetNoticesResponse } from 'types/response/get-notices-response';
import { IGetTrainingForm, IGetTrainingFormDetail } from 'types/response/training-form-response';
import { ICreateTrainingEvent } from 'types/request/training-form-request';
import { IResetAccountPasswordRequest } from 'types/request/reset-account-password-request';
import { IUpdateApplicationStatusRequest } from 'types/request/update-application-status-request';
import { IAllSupportingDocResponse } from 'types/response/supporting-doc-response';

interface ApiClientContextProps {
    applicationService: {
        getApplications: (params: IGetApplicationsRequest) => Promise<IGetApplicationsResponse>;
        getApplicationById: (id: number) => Promise<any>;
        getHomeInfo: (id: Number) => Promise<any>;
        createApplication: (data: any) => Promise<any>;
        getLicences: (params: any) => Promise<any>;
        deleteDraftApplicationById: (id: number) => Promise<any>;
        getApplicationHash: (data: any) => Promise<any>;
        createDraftUnstructuredApplication: (data: SaveUnstructuredApplicationData) => Promise<any>;
        createUnstructuredApplication: (data: CreateUnstructuredApplication) => Promise<any>;
        getDraftUnstructuredApplicationById: (id: number) => Promise<any>;
        getUnstructuredApplicationById: (id: number) => Promise<any>;
        deleteDraftUnstructuredApplicationById: (id: number) => Promise<any>;
        getIsHomeClosureByHomeId: (id: number) => Promise<any>;
        surrenderLicenceByHomeId: (id: number) => Promise<any>;
        getAdhocForms: () => Promise<any>;
        createAdhocForm: (params: any) => Promise<any>;
        getAdhocFormById: (id: number) => Promise<any>;
        updateAdhocFormById: (id: number, data: any) => Promise<any>;
        submitAdhocFormById: (id: number, data: any) => Promise<any>;
        getUnstructuredRecordOfHomeById: (id: number, appType: string) => Promise<any>;
        getTrainingForms: () => Promise<IGetTrainingForm[]>;
        getTrainingFormById: (id: number) => Promise<IGetTrainingFormDetail>;
        updateTrainingFormById: (id: number, data: Partial<ICreateTrainingEvent>) => Promise<any>;
        updateDocumentsById: (id: number, data: any) => Promise<any>;
        updateApplicationStatusById: (id: number, data: IUpdateApplicationStatusRequest) => Promise<any>;
        generatePDFOfPendingForm: (data: any) => Promise<any>;
    };
    recordService: {};
    systemService: {
        getAllSelection: () => Promise<ISelectionResponse[]>;
        getDocument: (id: number) => Promise<any>;
        getDocumentContentById: (id: number) => Promise<any>;
        getAllApplicationTypes: () => Promise<IApplicationTypeResponse[]>;
        getNotices: (params: IGetNoticesRequest) => Promise<IGetNoticesResponse>;
        getNoticeById: (id: number) => Promise<any>;
        markNoticeRead: (id: number) => Promise<any>;
        getAllSupportingDoc: () => Promise<IAllSupportingDocResponse[]>;
        getSupportingDocumentsById: (id: number) => Promise<any>;
        getApplicationTypeIdAndNameByCode: (code: string) => Promise<any>;
    };
    userService: {
        getUsers: (params: any) => Promise<any>;
        updateStatus: (data: any, id: number) => Promise<any>;
        createHomeUser: (data: any) => Promise<any>;
        updateHomeUser: (data: any, id: number) => Promise<any>;
        getUserRoles: (params: any) => Promise<IUserRoleResponse[]>;
        getUserById: (id: number) => Promise<any>;
        verifyToken: (token: any) => Promise<any>;
        initUser: (data: any) => Promise<any>;
        changePassword: (data: any) => Promise<any>;
        getAssociatedAccounts: (id: number) => Promise<any>;
        createAssociatedAccount: (id: number, data: any) => Promise<any>;
        deleteAssociatedAccount: (id: number, params: any) => Promise<any>;
        getOtp: (username: string) => Promise<any>;
        forgotPassword: (loginId: string) => Promise<any>;
        verifyForgotPasswordToken: (token: string) => Promise<any>;
        resetAccountPassword: (data: IResetAccountPasswordRequest) => Promise<any>;
        resetPassword: (id: any) => Promise<any>;
        unlinkIamsmart: () => Promise<any>;
    };
    publicService: {
        getLicence: (licenceNo: String) => Promise<any>;
        downloadLicense: (licenceNo: string) => Promise<any>;
        anonymousSigningCallback: (params: any) => Promise<any>;
        createAnonymousSigningRequest: (data: any) => Promise<any>;
        getAnonymousSigningResult: (businessId: string) => Promise<any>;
        createNewLicenceApplication: (data: any) => Promise<any>;
        getAllSelectionForNewLicenceApplication: () => Promise<ISelectionResponse[]>;
        generateApplicationPDF: (data: any) => Promise<any>;
        getApplicationHashCode: (data: any) => Promise<any>;
    };
    request: (config: ICustomRequestConfig) => Promise<any>;
}

const ApiClientContext = createContext<ApiClientContextProps>({
    applicationService: {
        getApplications: (params: IGetApplicationsRequest) => new Promise<IGetApplicationsResponse>(() => null),
        getApplicationById: (id: number) => new Promise(() => null),
        getHomeInfo: (id: Number) => new Promise(() => null),
        createApplication: (data: any) => new Promise(() => null),
        getLicences: (params: any) => new Promise(() => null),
        deleteDraftApplicationById: (id: number) => new Promise(() => null),
        getApplicationHash: (data: any) => new Promise(() => null),
        createDraftUnstructuredApplication: (data: any) => new Promise(() => null),
        createUnstructuredApplication: (data: any) => new Promise(() => null),
        getDraftUnstructuredApplicationById: (id: number) => new Promise(() => null),
        getUnstructuredApplicationById: (id: number) => new Promise(() => null),
        deleteDraftUnstructuredApplicationById: (id: number) => new Promise(() => null),
        getIsHomeClosureByHomeId: (id: number) => new Promise(() => null),
        surrenderLicenceByHomeId: (id: number) => new Promise(() => null),
        getAdhocForms: () => new Promise<any>(() => null),
        createAdhocForm: (data: any) => new Promise(() => null),
        getAdhocFormById: (id: number) => new Promise(() => null),
        updateAdhocFormById: (id: number, data: any) => new Promise(() => null),
        submitAdhocFormById: (id: number, data: any) => new Promise(() => null),
        getUnstructuredRecordOfHomeById: (id: number, appType: string) => new Promise(() => null),
        getTrainingForms: () => new Promise<IGetTrainingForm[]>(() => null),
        getTrainingFormById: (id: number) => new Promise<IGetTrainingFormDetail>(() => null),
        updateTrainingFormById: (id: number, data: Partial<ICreateTrainingEvent>) => new Promise<any>(() => null),
        updateDocumentsById: (id: number, data: any) => new Promise(() => null),
        updateApplicationStatusById: (id: number, data: IUpdateApplicationStatusRequest) => new Promise(() => null),
        generatePDFOfPendingForm: (data: any) => new Promise(() => null)
    },
    recordService: {},
    systemService: {
        getAllSelection: () => new Promise<ISelectionResponse[]>(() => []),
        getDocument: () => new Promise(() => null),
        getDocumentContentById: () => new Promise(() => null),
        getAllApplicationTypes: () => new Promise<IApplicationTypeResponse[]>(() => []),
        getNotices: (params: IGetNoticesRequest) => new Promise<IGetNoticesResponse>(() => null),
        getNoticeById: (id: number) => new Promise(() => null),
        markNoticeRead: (id: number) => new Promise(() => null),
        getAllSupportingDoc: () => new Promise<IAllSupportingDocResponse[]>(() => null),
        getSupportingDocumentsById: (id: number) => new Promise(() => null),
        getApplicationTypeIdAndNameByCode: (code: string) => new Promise(() => null)
    },
    userService: {
        getUsers: (params: any) => new Promise(() => null),
        updateStatus: (data: any, id: number) => new Promise(() => null),
        createHomeUser: (data: any) => new Promise(() => null),
        updateHomeUser: (data: any, id: number) => new Promise(() => null),
        getUserRoles: (params: any) => new Promise(() => []),
        getUserById: (id: number) => new Promise(() => null),
        verifyToken: (token: any) => new Promise(() => null),
        initUser: (data: any) => new Promise(() => null),
        changePassword: (data: any) => new Promise(() => null),
        getAssociatedAccounts: (id: number) => new Promise(() => null),
        createAssociatedAccount: (id: number, data: any) => new Promise(() => null),
        deleteAssociatedAccount: (id: number, params: any) => new Promise(() => null),
        getOtp: (username: string) => new Promise(() => null),
        forgotPassword: (loginId: string) => new Promise(() => null),
        verifyForgotPasswordToken: (token: string) => new Promise(() => null),
        resetAccountPassword: (data: IResetAccountPasswordRequest) => new Promise(() => null),
        resetPassword: (id: any) => new Promise(() => null),
        unlinkIamsmart: () => new Promise(() => null)
    },
    publicService: {
        getLicence: (licenceNo: String) => new Promise(() => null),
        downloadLicense: (licenceNo: string) => new Promise(() => null),
        anonymousSigningCallback: (params: any) => new Promise(() => null),
        createAnonymousSigningRequest: (data: any) => new Promise(() => null),
        getAnonymousSigningResult: (businessId: string) => new Promise(() => null),
        createNewLicenceApplication: (data: any) => new Promise(() => null),
        getAllSelectionForNewLicenceApplication: () => new Promise<ISelectionResponse[]>(() => []),
        generateApplicationPDF: (data: any) => new Promise(() => null),
        getApplicationHashCode: (data: any) => new Promise(() => null)
    },
    request: (config: ICustomRequestConfig) => new Promise(() => null)
});

const ApiClientProvider: FC = ({ children }) => {
    const { user } = useAuth();
    const dialog = useConfirmDialog();

    axios.interceptors.request.use(
        (config) => {
            const oldHeaders = config.headers;
            const newHeaders = {
                ...oldHeaders,
                Accept: 'application/json',
                apikey: `${window.config.API_KEY}`
            };

            return { ...config, headers: newHeaders };
        },
        (error) => {
            Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        (resp) => resp,
        (err) => {
            if (err?.response?.status === 422 && err?.response?.data?.errorObject?.errCodes?.length > 0) {
                const errorCode = err.response.data.errorObject.errCodes.find((c: any) => c.errCode);
                if (errorCode) {
                    dialog.setDialog({
                        title: 'error',
                        content: errorCode.errCode,
                        cancelBtn: 'ok'
                    });
                    return;
                }
            }

            dialog.setDialog({
                title: 'error',
                content: 'error.system',
                cancelBtn: 'ok'
            });
        }
    );

    // Application Service
    const getApplications = async (params: IGetApplicationsRequest): Promise<IGetApplicationsResponse> =>
        request(new CustomRequestConfig(APPLICATION.GET_APPLICATIONS, 'GET', undefined, undefined, params));
    const getApplicationById = async (id: number) => request(new CustomRequestConfig(APPLICATION.GET_APPLICATION_BY_ID, 'GET', [id]));
    const getHomeInfo = async (id: Number) => request(new CustomRequestConfig(APPLICATION.GET_HOME_INFO, 'GET', [id]));
    const createApplication = async (data: any) =>
        request(new CustomRequestConfig(APPLICATION.CREATE_APPLICATION, 'POST', undefined, data));
    const getLicences = async (params: any) =>
        request(new CustomRequestConfig(APPLICATION.SEARCH_LICENCES, 'GET', undefined, undefined, params));
    const deleteDraftApplicationById = async (id: number) =>
        request(new CustomRequestConfig(APPLICATION.DELETE_DRAFT_APPLICATION_BY_ID, 'DELETE', [id]));
    const getApplicationHash = (data: any) => request(new CustomRequestConfig(APPLICATION.GET_APPLICATION_HASH, 'POST', undefined, data));
    // Unstructured Application
    const createDraftUnstructuredApplication = async (data: SaveUnstructuredApplicationData) =>
        request(new CustomRequestConfig(UNSTRUCTURED_APPLICATION.SAVE_DRAFT_UNSTRUCTURED_APPLICATION, 'POST', undefined, data));
    const createUnstructuredApplication = async (data: CreateUnstructuredApplication) =>
        request(new CustomRequestConfig(UNSTRUCTURED_APPLICATION.PUT_UNSTRUCTURED_APPLICATION, 'PUT', undefined, data));
    const getDraftUnstructuredApplicationById = async (id: number) =>
        request(new CustomRequestConfig(UNSTRUCTURED_APPLICATION.GET_DRAFT_UNSTRUCTURED_APPLICATION_BY_ID, 'GET', [id]));
    const getUnstructuredApplicationById = async (id: number) =>
        request(new CustomRequestConfig(UNSTRUCTURED_APPLICATION.GET_UNSTRUCTURED_APPLICATION_BY_ID, 'GET', [id]));
    const deleteDraftUnstructuredApplicationById = async (id: number) =>
        request(new CustomRequestConfig(UNSTRUCTURED_APPLICATION.DELETE_DRAFT_UNSTRUCTURED_APPLICATION_BY_ID, 'DELETE', [id]));
    const getIsHomeClosureByHomeId = async (id: number) =>
        request(new CustomRequestConfig(UNSTRUCTURED_APPLICATION.GET_IS_HOME_CLOSURE_BY_ID, 'GET', [id]));
    const surrenderLicenceByHomeId = async (id: number) =>
        request(new CustomRequestConfig(UNSTRUCTURED_APPLICATION.SURRENDER_ALL_LICENCE, 'DELETE', [id]));
    const getAdhocForms = async () => request(new CustomRequestConfig(UNSTRUCTURED_APPLICATION.GET_ADHOC_FORMS, 'GET'));
    const createAdhocForm = async (data: any) =>
        request(new CustomRequestConfig(UNSTRUCTURED_APPLICATION.CREATE_ADHOC_FORM, 'POST', undefined, data));
    const getAdhocFormById = async (id: number) =>
        request(new CustomRequestConfig(UNSTRUCTURED_APPLICATION.GET_ADHOC_FORM_BY_ID, 'GET', [id]));
    const updateAdhocFormById = async (id: number, data: any) =>
        request(new CustomRequestConfig(UNSTRUCTURED_APPLICATION.UPDATE_ADHOC_FORM_BY_ID, 'PUT', [id], data));
    const submitAdhocFormById = async (id: number, data: any) =>
        request(new CustomRequestConfig(UNSTRUCTURED_APPLICATION.SUBMIT_ADHOC_FORM, 'PUT', [id], data));
    const getUnstructuredRecordOfHomeById = async (id: number, appType: string) =>
        request(new CustomRequestConfig(UNSTRUCTURED_APPLICATION.GET_UNSTRUCTURED_RECORD_OF_HOME_BY_ID, 'GET', [id, appType]));
    const getTrainingForms = async () => request(new CustomRequestConfig(UNSTRUCTURED_APPLICATION.GET_ALL_TRAINING_FORMS, 'GET'));
    const getTrainingFormById = async (id: number) =>
        request(new CustomRequestConfig(UNSTRUCTURED_APPLICATION.GET_TRAINING_FORM_BY_ID, 'GET', [id]));
    const updateTrainingFormById = async (id: number, data: Partial<ICreateTrainingEvent>) =>
        request(new CustomRequestConfig(UNSTRUCTURED_APPLICATION.UPDATE_TRAINING_FORM_BY_ID, 'PUT', [id], data));
    const updateDocumentsById = async (id: number, data: any) =>
        request(new CustomRequestConfig(APPLICATION.UPDATE_DOCUMENTS_BY_APPLICATION_ID, 'PUT', [id], data));
    const updateApplicationStatusById = async (id: number, data: IUpdateApplicationStatusRequest) =>
        request(new CustomRequestConfig(APPLICATION.UPDATE_APPLICATION_STATUS, 'POST', [id], data));
    const generatePDFOfPendingForm = async (data: any) =>
        request(new CustomRequestConfig(UNSTRUCTURED_APPLICATION.GENERATE_PDF_OF_PENDING_FORM, 'POST', undefined, data));

    // Record Service

    // System Service
    const getAllSelection = async () => request(new CustomRequestConfig(SYSTEM.GET_ALL_SELECTION, 'GET', undefined, undefined, undefined));
    const getDocument = async (id: number) => request(new CustomRequestConfig(SYSTEM.GET_DOCUMENT_BY_ID, 'GET', [id]));
    const getDocumentContentById = async (id: number) => request(new CustomRequestConfig(SYSTEM.GET_DOCUMENT_CONTENT_BY_ID, 'GET', [id]));
    const getAllApplicationTypes = async () =>
        request(new CustomRequestConfig(SYSTEM.GET_ALL_APPLICATION_TYPES, 'GET', undefined, undefined, undefined));
    const getNotices = async (params: IGetNoticesRequest): Promise<IGetNoticesResponse> =>
        request(new CustomRequestConfig(SYSTEM.GET_NOTICES, 'GET', undefined, undefined, params));
    const getNoticeById = async (id: number) => request(new CustomRequestConfig(SYSTEM.GET_NOTICE_BY_ID, 'GET', [id]));
    const markNoticeRead = async (id: number) => request(new CustomRequestConfig(SYSTEM.MARK_NOTICE_READ, 'PATCH', [id]));
    const getAllSupportingDoc = async () =>
        request(new CustomRequestConfig(SYSTEM.GET_ALL_SUPPORTING_DOCUMENTS, 'GET', undefined, undefined, undefined));
    const getSupportingDocumentsById = async (id: number) =>
        request(new CustomRequestConfig(SYSTEM.GET_SUPPORTING_DOCUMENTS_BY_APPLICATION_TYPE_ID, 'GET', [id]));
    const getApplicationTypeIdAndNameByCode = async (code: string) =>
        request(new CustomRequestConfig(SYSTEM.GET_APPLICATION_NAME_AND_TYPE_ID_BY_TYPE_CODE, 'GET', [code]));

    // User Service
    const getUsers = async (params: any) => request(new CustomRequestConfig(USER.GET_HOME_USERS, 'GET', undefined, undefined, params));
    const updateStatus = async (data: any, id: number) =>
        request(new CustomRequestConfig(USER.UPDATE_HOME_USER_STATUS, 'POST', [id], data));
    const createHomeUser = async (data: any) => request(new CustomRequestConfig(USER.CREATE_HOME_USER, 'POST', undefined, data));
    const updateHomeUser = async (data: any, id: number) => request(new CustomRequestConfig(USER.UPDATE_HOME_USER, 'POST', [id], data));
    const getUserRoles = async (params: any): Promise<IUserRoleResponse[]> =>
        request(new CustomRequestConfig(USER.GET_USER_ROLES, 'GET', undefined, undefined, params));
    const getUserById = async (id: number) => request(new CustomRequestConfig(USER.GET_USER_BY_ID, 'GET', [id]));
    const verifyToken = async (token: any) =>
        request(new CustomRequestConfig(USER.VERIFY_TOKEN, 'POST', undefined, { token }, undefined, true));
    const initUser = async (data: any) => request(new CustomRequestConfig(USER.INIT_USER, 'POST', undefined, data, undefined, true));
    const changePassword = async (data: any) => request(new CustomRequestConfig(USER.CHANGE_PASSWORD, 'POST', undefined, data));
    const getAssociatedAccounts = async (id: number) => request(new CustomRequestConfig(USER.GET_ASSOCIATED_ACCOUNTS, 'GET', [id]));
    const createAssociatedAccount = async (id: number, data: any) =>
        request(new CustomRequestConfig(USER.CREATE_ASSOCIATED_ACCOUNT, 'POST', [id], data));
    const deleteAssociatedAccount = async (id: number, params: any) =>
        request(new CustomRequestConfig(USER.DELETE_ASSOCIATED_ACCOUNT, 'DELETE', [id], undefined, params));
    const getOtp = async (username: string) => request(new CustomRequestConfig(USER.GET_OTP, 'POST', undefined, { username }));
    const forgotPassword = async (loginId: string) => request(new CustomRequestConfig(USER.FORGOT_PASSWORD, 'POST', undefined, loginId));
    const verifyForgotPasswordToken = async (token: string) =>
        request(new CustomRequestConfig(USER.VERIFY_FORGOT_PASSWORD_TOKEN, 'POST', undefined, { token }));
    const resetAccountPassword = async (data: IResetAccountPasswordRequest) =>
        request(new CustomRequestConfig(USER.RESET_ACCOUNT_PASSWORD, 'POST', undefined, data));
    const resetPassword = async (id: any) => request(new CustomRequestConfig(USER.UPDATE_HOME_USER, 'PATCH', [id]));
    const unlinkIamsmart = async () => request(new CustomRequestConfig(USER.UNLINK_IAMSMART, 'DELETE'));

    // Public Service
    const getLicence = async (licenceNo: String) =>
        request(new CustomRequestConfig(PUPLIC.GET_LICENCE_BY_ID, 'GET', [licenceNo], undefined, undefined, true));
    const downloadLicense = async (licenceNo: string) =>
        request(new CustomRequestConfig(PUPLIC.DOWNLOAD_LICENCE_BY_ID, 'GET', [licenceNo], undefined, undefined, true));
    const anonymousSigningCallback = async (params: any) =>
        request(new CustomRequestConfig(PUPLIC.ANONYMOUS_SIGNING_CALLBACK, 'GET', undefined, undefined, params, true));
    const createAnonymousSigningRequest = async (data: any) =>
        request(new CustomRequestConfig(PUPLIC.CREATE_ANONYMOUS_SIGNING_REQUEST, 'POST', undefined, data, undefined, true));
    const getAnonymousSigningResult = (businessId: string) =>
        request(new CustomRequestConfig(PUPLIC.GET_ANONYMOUS_SIGNING_RESULT, 'POST', undefined, { businessId }, undefined, true));
    const createNewLicenceApplication = async (data: any) =>
        request(new CustomRequestConfig(PUPLIC.CREATE_NEW_LICENCE_APPLICATION, 'POST', undefined, data, undefined, true));
    const getAllSelectionForNewLicenceApplication = async () =>
        request(new CustomRequestConfig(PUPLIC.GET_ALL_SELECTIONS_FOR_NEW_LICENCE_APPLICATION, 'GET', undefined, undefined, undefined));
    const generateApplicationPDF = async (data: any) =>
        request(new CustomRequestConfig(PUPLIC.GENERATE_APPLICATION_PDF, 'POST', undefined, data, undefined, true));
    const getApplicationHashCode = (data: any) =>
        request(new CustomRequestConfig(PUPLIC.GET_APPLICATION_HASH_CODE, 'POST', undefined, data));

    const applicationService = {
        getApplications,
        getApplicationById,
        getHomeInfo,
        createApplication,
        getLicences,
        deleteDraftApplicationById,
        getApplicationHash,
        createDraftUnstructuredApplication,
        createUnstructuredApplication,
        getDraftUnstructuredApplicationById,
        getUnstructuredApplicationById,
        deleteDraftUnstructuredApplicationById,
        getIsHomeClosureByHomeId,
        surrenderLicenceByHomeId,
        getAdhocForms,
        createAdhocForm,
        getAdhocFormById,
        updateAdhocFormById,
        submitAdhocFormById,
        getUnstructuredRecordOfHomeById,
        getTrainingForms,
        getTrainingFormById,
        updateTrainingFormById,
        updateDocumentsById,
        updateApplicationStatusById,
        generatePDFOfPendingForm
    };
    const recordService = {};
    const systemService = {
        getAllSelection,
        getDocument,
        getDocumentContentById,
        getAllApplicationTypes,
        getNotices,
        getNoticeById,
        markNoticeRead,
        getAllSupportingDoc,
        getSupportingDocumentsById,
        getApplicationTypeIdAndNameByCode
    };
    const userService = {
        getUsers,
        updateStatus,
        createHomeUser,
        updateHomeUser,
        getUserRoles,
        getUserById,
        verifyToken,
        initUser,
        changePassword,
        getAssociatedAccounts,
        createAssociatedAccount,
        deleteAssociatedAccount,
        getOtp,
        forgotPassword,
        verifyForgotPasswordToken,
        resetAccountPassword,
        resetPassword,
        unlinkIamsmart
    };

    const publicService = {
        getLicence,
        downloadLicense,
        anonymousSigningCallback,
        createAnonymousSigningRequest,
        getAnonymousSigningResult,
        createNewLicenceApplication,
        getAllSelectionForNewLicenceApplication,
        generateApplicationPDF,
        getApplicationHashCode
    };

    const request = async (config: ICustomRequestConfig): Promise<any> => {
        const { publicApi, url, pathVariables, headers } = config;

        const urlWithPathVariables = injectVariablesToUrl(url, pathVariables);

        const overwrittenHeaders = publicApi
            ? headers
            : {
                  ...headers,
                  Authorization: user?.token ? `Bearer ${user?.token}` : ''
              };

        const axiosConfig = {
            ...config,
            url: urlWithPathVariables,
            headers: overwrittenHeaders
        };

        try {
            console.log('Request Config: ', axiosConfig);
            const response = await axios(axiosConfig);

            if (config.responseType === 'blob') {
                return response;
            }

            return response.data;
        } catch (e) {
            console.error(`request ${urlWithPathVariables} with error:${util.inspect(e)}`);
            return null;
        }
    };

    const injectVariablesToUrl = (url: string, pathVariables?: string[]) => {
        if (!pathVariables || pathVariables.length <= 0) {
            return url;
        }

        return util.format(url, ...pathVariables);
    };

    return (
        <ApiClientContext.Provider
            value={{
                applicationService,
                recordService,
                systemService,
                userService,
                publicService,
                request
            }}
        >
            {children}
        </ApiClientContext.Provider>
    );
};

export { ApiClientContext, ApiClientProvider };
