import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, Stack } from '@mui/material';
import { Close } from '@mui/icons-material';
import IamSmartButton from '../../forms-wizard/ValidationWizard/IamSmartButton';
import { FormattedMessage } from 'react-intl';
import { IamsmartContext } from 'contexts/IamsmartContext';
import { useContext } from 'react';

export interface LinkupPopupProps {
    open: boolean;
    onClose: () => void;
}

const LinkupPopup = ({ open, onClose }: LinkupPopupProps) => {
    const { linkup, linkupStep } = useContext(IamsmartContext);

    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return <RequestLinkup />;
            default:
                return 'Unknown step';
        }
    };

    const getStepTitle = (step: number): string => {
        switch (step) {
            case 0:
                return 'iamsmart.linkup';
            default:
                return 'Unknown step';
        }
    };

    const RequestLinkup = () => (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h4">
                    <FormattedMessage id="iamsmart.linkup.popup.content" />
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <IamSmartButton label="signature.continueWithIamSmart" onClick={linkup} />
            </Grid>
        </Grid>
    );
    return (
        <Dialog open={open} aria-labelledby="customized-dialog-title" onClose={onClose} maxWidth="md">
            <DialogTitle id="customized-dialog-title">
                <Stack direction="row">
                    <Typography variant="h3" flex={1} marginRight={5}>
                        <FormattedMessage id={getStepTitle(linkupStep)} />
                    </Typography>
                    <Close onClick={onClose} />
                </Stack>
            </DialogTitle>

            <DialogContent sx={{}} dividers>
                {getStepContent(linkupStep)}
            </DialogContent>

            <DialogActions>
                <Button variant="contained" color="error" autoFocus onClick={onClose}>
                    <FormattedMessage id="cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LinkupPopup;
