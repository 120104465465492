import { Grid, RadioGroup, FormControlLabel, Radio, Button, Typography, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';

function SelectUser() {
    const [searchParams] = useSearchParams();
    const state = searchParams.get('state');
    const [userList, setUserList] = useState([]);
    const getUsers = () => {
        const url = `${window.config.AUTH_SERVICE_DOMAIN}/api/getSelectUserList`;
        fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: `state=${state}`
        })
            .then(async (response) => {
                if (response.status === 200) {
                    const body = await response.json();
                    setUserList(body);
                } else {
                    const body = await response.json();
                    const errorCode = body?.errorObject?.errCodes?.find((c: any) => c.errCode)?.errCode ?? 'UNKNOWN';
                    window.location.href = `/?error=${errorCode}`;
                }
            })
            .catch(async (err) => {
                console.error(`login fetch error:${JSON.stringify(err)}`);
            });
    };

    useEffect(() => {
        getUsers();
    }, [state]);

    return (
        <Grid container direction="column" justifyContent="flex-end">
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                        <MainCard>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item xs={12} textAlign="center">
                                    <Typography variant="h1" fontWeight="100">
                                        <FormattedMessage id="iamsmart.selectAccount" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} />
                                <Grid item xs={12}>
                                    <form method="POST" action={`${window.config.AUTH_SERVICE_DOMAIN}/api/selectUser`}>
                                        <input type="hidden" name="state" value={state ?? ''} />
                                        <Grid sx={{ display: { xs: 'flex' }, flexDirection: 'column', alignItems: 'center' }}>
                                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="target">
                                                {userList.map(({ loginId }) => (
                                                    <FormControlLabel value={loginId} control={<Radio />} label={loginId} />
                                                ))}
                                            </RadioGroup>
                                            <Box sx={{ mt: 3 }}>
                                                <Button type="submit" variant="contained" fullWidth size="large" color="primary">
                                                    <FormattedMessage id="submit" />
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </form>
                                </Grid>
                            </Grid>
                        </MainCard>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SelectUser;
